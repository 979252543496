:root {
  --step--3: clamp(0.5rem, 0.45rem + 0.2vw, 0.7rem);
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

@media (max-width: 1024px) {
  .footer--address {
    font-size: var(--step-0); /* Larger size for mobile view */
  }

  .footer--mail {
    font-size: var(--step-0); /* Larger size for mobile view */
  }

  .thedot--text,
  .thedot--text2 {
    font-size: var(--step--1); /* Bigger text for mobile view */
  }
}


.footer--main--cont {
  background-image: url(../assets/images/footer/footerbg.png);
  min-height: 35vh;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

@font-face {
  font-family: GilroyMedium;
  src: url(../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.footer--address {
  font-size: var(--step--2);
  color: #fff;
  font-family: "GilroyMedium", sans-serif;
}

.footer--mail {
  font-size: var(--step--3);
  color: #fff;
  font-family: "GilroyMedium", sans-serif;
  padding-top: 1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.thedot--cont {
  background: #26873e;
}
.thedot--text {
  font-size: var(--step--3);
  text-align: right;
  padding: 0.5rem;
  color: #fff;
  font-family: "GilroyMedium", sans-serif;
}
.thedot--text2 {
  font-size: var(--step--3);
  text-align: left;
  padding: 0.5rem;
  color: #fff;
  font-family: "GilroyMedium", sans-serif;
}
.thedot--cont--child {
  display: flex;
  justify-content: space-evenly;
}
