:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

@font-face {
  font-family: GilroyRegular;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Light.otf);
}
@font-face {
  font-family: GilroyMedium;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.sub--cont--food {
  align-items: center;
  padding: 3rem 0.5rem;
  text-align: center;
}

.menu--cards--sect {
  align-items: center;
  text-align: center;
  padding: 3rem 0;
  position: relative;
}

.main--cards--title {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-0);
  padding: 1rem 0.5rem;
  color: #26873e;
}

.main--title--food {
  font-family: "GilroyRegular", sans-serif;
  font-size: var(--step-0);
  color: #26873e;
}

.main--sub--food {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-1);
  padding: 0.5rem 0;
  color: #26873e;
}

.main--para--food {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--1);

}
.breakfast{
  padding-right: 20px;
  margin-right: 20px;
}
.crossline--indulge {
  position: relative;
}
.crossline--indulge::before {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 25%;
  top: 50%;
  z-index: -2;
}
.crossline--indulge::after {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 25%;
  top: 50%;
  z-index: -2;
}

@media (max-width: 600px) {
  .crossline--indulge::before {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 10%;
    top: 50%;
    z-index: -2;
  }
  .crossline--indulge::after {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 10%;
    top: 50%;
    z-index: -2;
  }
}

.inspan {
  background-color: #fff;
  z-index: 2;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.food--btn {
  padding: 12px;
  margin-top: 1;
  color: #fff;
  background-color: #26873e;
  border: 1px solid #26873e;
  border: none;
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--2);
  transition: all 0.3s;
}

.food--btn:hover {
  background-color: #5cb572;
}

.food--btn--container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.food--card--title {
  font-size: var(--step-0);
  color: #26873e;
  font-family: "GilroyMedium", sans-serif;
}
