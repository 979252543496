:root {
  --step--3: clamp(0.62rem, 0.55rem + 0.33vw, 0.8rem);
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body.mobile-open {
  overflow-y: hidden;
}

.nav--flex {
  background: url(../assets/images/navBg.png);
  position: fixed;
  width: 100%;
  padding: 0.6rem;
  z-index: 9999;
  top: 0;
}

@font-face {
  font-family: GilroyMedium;
  src: url(../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.nav--links {
  text-decoration: none;
  color: inherit;
  font-family: "GilroyMedium", sans-serif;
  z-index: 10;
  color: #fff;
  font-size: var(--step--2);
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.mobile-nav {
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  transform: translateY(200%);
  z-index: 999;
  transition: transform 0.3s ease-in 0.7s;
  display: none;
}

.mobile-open .mobile-nav {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}

.mobile-nav .links {
  width: 100%;
  height: 100%;
  text-align: center;
}

.mobile-nav .links a {
  position: absolute;
  left: 50%;
  color: #000;
  font-size: 1.5rem;
}

.mobile-nav .links a:nth-child(1) {
  transform: translate(-50%, 30vh);
}

.mobile-nav .links a:nth-child(2) {
  transform: translate(-50%, 40vh);
}

.mobile-nav .links a:nth-child(3) {
  transform: translate(-50%, 50vh);
}

.mobile-nav .links a:nth-child(4) {
  transform: translate(-50%, 60vh);
}

.mobile-nav .links a:nth-child(5) {
  transform: translate(-50%, 70vh);
}

nav .container {
  display: flex;
  position: relative;
}

nav .logo {
  width: 100px;
  font-size: 3rem;
  text-transform: lowercase;
}

nav .logo span:nth-child(1) {
  color: var(--teal-1);
}

nav .logo span:nth-child(2) {
  color: var(--blue-1);
}

nav .logo span:nth-child(3) {
  color: var(--indigo-1);
}

nav .logo span:nth-child(4) {
  color: var(--pink-1);
}

nav .logo span:nth-child(5) {
  color: var(--red-1);
}

nav .burger {
  position: absolute;
  right: 0;
  top: calc(50% - 15px);
  height: 30px;
  width: 35px;
  cursor: pointer;
  display: none;
}

nav .burger .menu,
nav .burger .close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

nav .burger .menu {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav .burger .close {
  z-index: 1;
}

nav .burger .line {
  height: 3px;
  transform-origin: right;
  background: #edc66f;
}

nav .burger .close .line {
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s ease-in;
}

.mobile-open nav .burger .close .line:first-child {
  transform: rotate(225deg);
  opacity: 1;
  transition: all 0.6s ease-in 1s;
}

.mobile-open nav .burger .close .line:last-child {
  transform: rotate(-225deg);
  opacity: 1;
  transition: all 0.6s ease-in 1s;
}

nav .burger .menu .line:nth-child(1) {
  background: #edc66f;
  animation: 1s ease-out backwards returnLine1;
}

.mobile-open .burger .menu .line:nth-child(1) {
  animation: 0.8s ease-in forwards moveLine1;
}

nav .burger .menu .line:nth-child(2) {
  background: #edc66f;
  animation: 1s ease-out 0.2s backwards returnLine2;
}

.mobile-open .burger .menu .line:nth-child(2) {
  animation: 0.8s ease-in 0.15s forwards moveLine2;
}

nav .burger .menu .line:nth-child(3) {
  background: #edc66f;
  animation: 1s ease-out 0.4s backwards returnLine3;
}

.mobile-open .burger .menu .line:nth-child(3) {
  animation: 0.8s ease-in 0.3s forwards moveLine3;
}

nav .burger .menu .line:nth-child(4) {
  background: #edc66f;
  animation: 1s ease-out 0.6s backwards returnLine4;
}

.mobile-open .burger .menu .line:nth-child(4) {
  animation: 0.8s ease-in 0.45s forwards moveLine4;
}


nav .burger .menu .line:nth-child(5) {
  background: #edc66f;
  animation: 1s ease-out 0.6s backwards returnLine5;
}

.mobile-open .burger .menu .line:nth-child(5) {
  animation: 0.8s ease-in 0.45s forwards moveLine5;
}

.no-animation nav .burger .line {
  animation: none !important;
}

nav .links {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.link {
  margin-left: 25px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.link a {
  display: block;
  transition: color 0.25s ease-out;
}

section {
  padding: 60px 1rem;
}

.unav {
  cursor: pointer;
  position: relative;
  width: 10%;
  margin-bottom: 2em;
  border: none;
  text-decoration: none;
  color: #fff;
}

.unav::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #edc66f;
  bottom: 0;
  left: 0;
}

.unav--anim::after {
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.unav--anim:hover::after {
  transform: scale(1, 1);
}

section.main-content {
  padding-top: 140px;
  background: #fff;
  min-height: 200vh;
}

@media (max-width: 768px) {
  .mobile-nav {
    display: initial;
  }
  nav {
    padding: 1rem 2rem;
  }
  nav .burger {
    display: flex;
  }
  nav .links {
    display: none;
  }
}

@keyframes moveLine1 {
  5% {
    transform: translate(0, -3px) rotate(0deg);
  }
  60% {
    transform: translate(-4vw, 27vh) rotate(180deg);
  }
  100% {
    transform: translate(calc(-0.3rem - 50vw), calc(28vh + 8px)) rotate(180deg);
    background: #edc66f;
  }
}

@keyframes moveLine2 {
  5% {
    transform: translate(0, -3px) rotate(0deg);
  }
  60% {
    transform: translate(-4vw, 37vh) rotate(180deg);
  }
  100% {
    transform: translate(calc(-0.3rem - 50vw), calc(42vh + 2px)) rotate(180deg);
    background: #edc66f;
  }
}

@keyframes moveLine3 {
  5% {
    transform: translate(0, -3px) rotate(0deg);
  }
  60% {
    transform: translate(-4vw, 47vh) rotate(180deg);
  }
  100% {
    transform: translate(calc(-0.3rem - 50vw), calc(48vh - 6px)) rotate(180deg);
    background: #edc66f;
  }
}

@keyframes moveLine4 {
  5% {
    transform: translate(0, -3px) rotate(0deg);
  }
  60% {
    transform: translate(-4vw, 57vh) rotate(180deg);
  }
  100% {
    transform: translate(calc(-0.3rem - 50vw), calc(58vh - 13px)) rotate(180deg);
    background: #edc66f;
  }
}

@keyframes moveLine5 {
  5% {
    transform: translate(0, -3px) rotate(0deg);
  }
  60% {
    transform: translate(-4vw, 67vh) rotate(180deg);
  }
  100% {
    transform: translate(calc(-0.3rem - 50vw), calc(68vh - 18px)) rotate(180deg);
    background: #edc66f;
  }
}


@keyframes returnLine1 {
  0% {
    transform: translate(calc(3.2rem - 50vw), calc(30vh + 4px));
    background: #edc66f;
  }
  5% {
    transform: translate(calc(3rem - 50vw), calc(30vh + 4px));
  }
  40% {
    transform: translate(20vw, calc(30vh + 4px));
  }
  60% {
    transform: translate(20vw, 0);
    background: #edc66f;
  }
}

@keyframes returnLine2 {
  0% {
    transform: translate(calc(3.2rem - 50vw), calc(40vh - 4px));
    background: #edc66f;
  }
  5% {
    transform: translate(calc(3rem - 50vw), calc(40vh - 4px));
  }
  40% {
    transform: translate(20vw, calc(40vh - 4px));
  }
  60% {
    transform: translate(20vw, 0);
    background: #edc66f;
  }
}

@keyframes returnLine3 {
  0% {
    transform: translate(calc(3.2rem - 50vw), calc(50vh - 12px));
    background: #edc66f;
  }
  5% {
    transform: translate(calc(3rem - 50vw), calc(50vh - 12px));
  }
  40% {
    transform: translate(20vw, calc(50vh - 12px));
  }
  60% {
    transform: translate(20vw, 0);
    background: #edc66f;
  }
}

@keyframes returnLine4 {
  0% {
    transform: translate(calc(3.2rem - 50vw), calc(60vh - 20px));
    background: #edc66f;
  }
  5% {
    transform: translate(calc(3rem - 50vw), calc(60vh - 20px));
  }
  40% {
    transform: translate(20vw, calc(60vh - 20px));
  }
  60% {
    transform: translate(20vw, 0);
    background: #edc66f;
  }
}

@keyframes returnLine5 {
  0% {
    transform: translate(calc(3.2rem - 50vw), calc(70vh - 26px));
    background: #edc66f;
  }
  5% {
    transform: translate(calc(3rem - 50vw), calc(70vh - 26px));
  }
  40% {
    transform: translate(20vw, calc(70vh - 26px));
  }
  60% {
    transform: translate(20vw, 0);
    background: #edc66f;
  }
}