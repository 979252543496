:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

.fut--plans {
  font-size: var(--step-0);
  font-family: "GilroyMedium", sans-serif;
}

.main--cont--bgasimage {
  background-image: url(../../assets/images/food/bgfoodcomp.png);
  min-height: 71vh;
  margin: 3rem 0;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 50;
}

@font-face {
  font-family: GilroyRegular;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Light.otf);
}

@font-face {
  font-family: GilroyMedium;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.cont--in--bgasimage {
  background: #fff;
  margin: 0 10%;
  padding-top: 1rem;
}

.main--title--bgasimage {
  font-family: "GilroyRegular", sans-serif;
  font-size: var(--step-0);
  color: #26873e;
  text-align: center;
}

.main--sub--bgasimage {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-1);
  padding: 0.5rem 0;
  color: #26873e;
  text-align: center;
  background-color: #fff;
}

.main--para--bgasimage {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--1);
  padding: 0.5rem 0.7rem;
  text-align: center;
}

.cont--in--homesectcont {
  padding: 2rem;
}

.crossline--bgasimage {
  position: relative;
}

.crossline--bgasimage::before {
  content: "";
  display: block;
  width: 17%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 20%;
  top: 50%;
  z-index: -2;
}

.crossline--bgasimage::after {
  content: "";
  display: block;
  width: 17%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 20%;
  top: 50%;
  z-index: -2;
}

.bgspan {
  background-color: #fff;
  z-index: 2;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.bgasimage--btn {
  padding: 10px 28px;
  margin-top: 8px;
  color: #fff;
  background-color: #26873e;
  border: 1px solid #26873e;
  border: none;
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--2);
  transition: all 0.3s;
  text-decoration: none;
}

@media (max-width: 450px) {
  .bgasimage--btn {
    margin-top: 5px;
  }
  .crossline--bgasimage::after,
  .crossline--bgasimage::before {
    visibility: hidden;
  }
}

.bgasimage--btn:hover {
  background-color: #5cb572;
  color: #fff;
}

.bgasimage--btn--container {
  display: flex;
  justify-content: center;
  align-items: center;
}
