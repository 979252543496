.video-container {
  width: 100%;
  height: 91vh;
  overflow: hidden;
  position: relative;
  margin-top: 7vh;
}

.home--bg--video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.overlay {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: #26873e;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: 0.3;
}

.logo--overlay {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
}
