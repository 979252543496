.tab--list--admin {
}

.tab--list--admin:hover {
  background-color: #c7dbcc;
}

.affiliations-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.affiliation-box {
  background-color: #fff;
  border-radius: 12px;
  border: 3px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .affiliations-container > div {
    flex: 1 0 calc(50% - 16px); /* Two boxes per row on laptop and above */
  }
}
@media (max-width: 768px) {
  .affiliations-container > div {
    flex: 1 0 100%; /* One box per row on mobile */
  }
}