:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

.main--cont--shuttlear {
  background-image: url(../assets/images/ent/shuttlebg.png);
  min-height: 91vh;
  background-position: center;
  background-size: cover;
  margin-top: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

@font-face {
  font-family: GilroyMedium;
  src: url(../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.shuttlear--main--title {
  font-size: var(--step-3);
  letter-spacing: 0.2em;
  font-family: "GilroyMedium", sans-serif;
  color: #fff;
  font-weight: 800;
  z-index: 10;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.textsa {
  position: relative;
}
.textsa::before {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 23%;
  top: 50%;
}
.textsa::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  right: 23%;
  top: 50%;
}

@media (max-width: 1440px) {
  .textsa::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 21%;
    top: 50%;
  }
  .textsa::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 21%;
    top: 50%;
  }
}
@media (max-width: 1350px) {
  .textsa::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 19%;
    top: 50%;
  }
  .textsa::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 19%;
    top: 50%;
  }
}
@media (max-width: 1250px) {
  .textsa::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 17%;
    top: 50%;
  }
  .textsa::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 17%;
    top: 50%;
  }
}
@media (max-width: 1190px) {
  .textsa::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 15%;
    top: 50%;
  }
  .textsa::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 15%;
    top: 50%;
  }
}
@media (max-width: 1110px) {
  .textsa::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 13%;
    top: 50%;
  }
  .textsa::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 13%;
    top: 50%;
  }
}
@media (max-width: 1000px) {
  .textsa::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 10%;
    top: 50%;
  }
  .textsa::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 10%;
    top: 50%;
  }
}
@media (max-width: 900px) {
  .textsa::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 9%;
    top: 50%;
  }
  .textsa::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 9%;
    top: 50%;
  }
}
@media (max-width: 760px) {
  .textsa::before {
    visibility: hidden;
  }
  .textsa::after {
    visibility: hidden;
  }
}
