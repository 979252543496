:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

@font-face {
  font-family: GilroyMedium;
  src: url(../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.main--cont--bear {
  margin-bottom: 2rem;
}

.name--bear {
  font-size: var(--step--1);
  font-family: "GilroyMedium", sans-serif;
  color: #26873e;
  margin-top: 15px;
}
.name--com {
  font-size: var(--step--1);
  font-family: "GilroyMedium", sans-serif;
  color: #131313;
  margin-top: 15px;
  font-weight: bold;
}

.position--bear {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--2);
}

.bear--main--title {
  font-size: var(--step-1);
  font-family: "GilroyMedium", sans-serif;
  color: #26873e;
  text-align: center;
}

.textbr {
  position: relative;
}
.textbr::before {
  content: "";
  display: block;
  width: 8%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 25%;
  top: 50%;
}
.textbr::after {
  content: "";
  display: block;
  width: 8%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 25%;
  top: 50%;
}

@media (max-width: 1440px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 25%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 25%;
    top: 50%;
  }
}
@media (max-width: 1350px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 25%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 25%;
    top: 50%;
  }
}
@media (max-width: 1250px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 25%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 25%;
    top: 50%;
  }
}
@media (max-width: 1190px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 24%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 24%;
    top: 50%;
  }
}
@media (max-width: 1110px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 22%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 22%;
    top: 50%;
  }
}
@media (max-width: 1000px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 22%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 22%;
    top: 50%;
  }
}
@media (max-width: 900px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 20%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 20%;
    top: 50%;
  }
}
@media (max-width: 760px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 19%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 19%;
    top: 50%;
  }
}
@media (max-width: 680px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 17%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 17%;
    top: 50%;
  }
}
@media (max-width: 615px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 15%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 15%;
    top: 50%;
  }
}
@media (max-width: 524px) {
  .textbr::before {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 5%;
    top: 50%;
  }
  .textbr::after {
    content: "";
    display: block;
    width: 8%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 5%;
    top: 50%;
  }
}
@media (max-width: 414px) {
  .textbr::before {
    visibility: hidden;
  }
  .textbr::after {
    visibility: hidden;
  }
}
