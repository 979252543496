:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

@font-face {
  font-family: GilroyMedium;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.main--title--carrom {
  font-size: var(--step-1);
  font-family: "GilroyMedium", sans-serif;
  color: #26873e;
}

.cross--line {
  position: relative;
}
.cross--line::before {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 9%;
  top: 50%;
  z-index: -2;
}
.cross--line::after {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 9%;
  top: 50%;
  z-index: -2;
}

.cspan {
  background-color: #fff;
  z-index: 2;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.main--para--carrom {
  font-size: var(--step-0);
  text-align: center;
  font-family: "GilroyMedium", sans-serif;
}

.entertain--btn {
  padding: 12px;
  margin-top: 2rem;
  color: #fff;
  background-color: #26873e;
  border: 1px solid #26873e;
  border: none;
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--2);
  transition: all 0.3s;
}

.entertain--btn:hover {
  background-color: #5cb572;
}

.ent--btn--container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .res--bottom--ent {
    order: 2;
  }
  .res--top--ent {
    order: 1;
  }
  .row--ent {
    margin-bottom: 4rem;
  }
  .entertain--btn {
    margin-top: 0.5rem;
  }
}
