:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

.follow--main--cont {
  background-image: url(../assets/images/footer/followbg.png);
  min-height: 51vh;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.div--in--followbg {
  margin-top: -3rem;
}

@font-face {
  font-family: GilroyMedium;
  src: url(../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.follow--main--title {
  font-size: var(--step-2);
  letter-spacing: 0.2em;
  font-family: "GilroyMedium", sans-serif;
  color: #26873e;
  z-index: 10;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.textfo {
  position: relative;
}
.textfo::before {
  content: "";
  display: block;
  width: 150px;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 25%;
  top: 50%;
}
.textfo::after {
  content: "";
  display: block;
  width: 150px;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 25%;
  top: 50%;
}

@media (max-width: 1440px) {
  .textfo::before {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 23%;
    top: 50%;
  }
  .textfo::after {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 23%;
    top: 50%;
  }
}
@media (max-width: 1350px) {
  .textfo::before {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 21%;
    top: 50%;
  }
  .textfo::after {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 21%;
    top: 50%;
  }
}
@media (max-width: 1250px) {
  .textfo::before {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 19%;
    top: 50%;
  }
  .textfo::after {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 19%;
    top: 50%;
  }
}
@media (max-width: 1190px) {
  .textfo::before {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 17%;
    top: 50%;
  }
  .textfo::after {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 17%;
    top: 50%;
  }
}
@media (max-width: 1110px) {
  .textfo::before {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 15%;
    top: 50%;
  }
  .textfo::after {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 15%;
    top: 50%;
  }
}
@media (max-width: 1000px) {
  .textfo::before {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 12%;
    top: 50%;
  }
  .textfo::after {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 12%;
    top: 50%;
  }
}
@media (max-width: 900px) {
  .textfo::before {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 7%;
    top: 50%;
  }
  .textfo::after {
    content: "";
    display: block;
    width: 150px;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 7%;
    top: 50%;
  }
}
@media (max-width: 760px) {
  .textfo::before {
    visibility: hidden;
  }
  .textfo::after {
    visibility: hidden;
  }
}

.insta--text {
  text-align: center;
  color: #26873e;
  font-size: var(--step--1);
  padding: 1rem 0;
}

.social-container {
  display: flex;
  padding: 25px 50px;
  justify-content: center;
}

.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

.social:hover {
  transform: translateY(-10px);
  color: #26873e;
}

.youtube {
  color: #26873e;
}

.facebook {
  color: #26873e;
}

.twitter {
  color: #26873e;
}

.instagram {
  color: #26873e;
}
