:root {
  --step--3: clamp(0.62rem, 0.55rem + 0.33vw, 0.8rem);
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

.main--cont--society {
  min-height: 65vh;
  background-size: cover;
  background-position: center;
  background-image: url(../../assets/images/societybg.png);
  position: relative;
}

@font-face {
  font-family: GilroyMedium;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.society--heading {
  font-size: var(--step-2);
  text-align: center;
  font-family: "GilroyMedium", sans-serif;
  color: #26873e;
  padding: 0 0.8rem;
  margin-bottom: 30px;
}

.society--card--name {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-0);
}
.society--textcol {
  width: 40%;
  border-radius: 25px;
  padding: 2.3rem;
  margin: 1rem 0;
}

@media (max-width: 1023px) {
  .society--textcol {
    width: 90%;
    padding: 1.5rem;
    margin-top: 2.5rem;
  }
}
