:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

.main--cont--testim {
  min-height: 40vh;
  background-color: #fff;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@font-face {
  font-family: GilroyRegular;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Light.otf);
}
@font-face {
  font-family: GilroyMedium;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.cont--in--testim {
  background: #26873e;
  margin: 5% 10%;
  padding-top: 1rem;
}

.main--name--testim {
  font-family: "GilroyRegular", sans-serif;
  font-size: var(--step-0);
  color: #fff;
  text-align: center;
  margin-left: 0.5rem;
}

.main--para--testim {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--1);
  padding: 2rem 0;
  text-align: center;
  color: #fff;
}

.main--sub--testim {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-1);
  padding-top: 1.5rem;
  color: #26873e;
  text-align: center;
  background-color: #fff;
}

.crossline--testim {
  position: relative;
}
.crossline--testim::before {
  content: "";
  display: block;
  width: 7%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 33%;
  top: 50%;
  z-index: 0;
}
.crossline--testim::after {
  content: "";
  display: block;
  width: 7%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 33%;
  top: 50%;
  z-index: 0;
}

@media (max-width: 735px) {
  .crossline--testim::before {
    content: "";
    display: block;
    width: 7%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 27%;
    top: 50%;
    z-index: 0;
  }
  .crossline--testim::after {
    content: "";
    display: block;
    width: 7%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 27%;
    top: 50%;
    z-index: 0;
  }
}

@media (max-width: 1095px) {
  .crossline--testim::before {
    content: "";
    display: block;
    width: 7%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 30%;
    top: 50%;
    z-index: 0;
  }
  .crossline--testim::after {
    content: "";
    display: block;
    width: 7%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 30%;
    top: 50%;
    z-index: 0;
  }
}
@media (max-width: 665px) {
  .crossline--testim::before {
    content: "";
    display: block;
    width: 7%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 24%;
    top: 50%;
    z-index: 0;
  }
  .crossline--testim::after {
    content: "";
    display: block;
    width: 7%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 24%;
    top: 50%;
    z-index: 0;
  }
}
@media (max-width: 410px) {
  .crossline--testim::before {
    visibility: hidden;
  }
  .crossline--testim::after {
    visibility: hidden;
  }
}

.tsspan {
  background-color: #fff;
  z-index: 3;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
