:root {
  --step--3: clamp(0.62rem, 0.55rem + 0.33vw, 0.8rem);
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

.main--cont--prominent {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@font-face {
  font-family: GilroyRegular;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Light.otf);
}
@font-face {
  font-family: GilroyMedium;
  src: url(../../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.cont--in--prominent {
  background: #fff;
  margin: 0 10%;
  padding-top: 1rem;
}
.main--title--prominent {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-1);
  color: #26873e;
  text-align: center;
}

.main--sub--prominent {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-1);
  padding: 0.5rem 0;
  color: #26873e;
  text-align: center;
  background-color: #fff;
}

.main--para--prominent {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--1);
  padding: 1.2rem 0.7rem;
  text-align: center;
}

.cont--in--homesectcont {
  padding: 2rem;
}

.crossline--prominent {
  position: relative;
}
.crossline--prominent::before {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 25%;
  top: 50%;
  z-index: -2;
}
.crossline--prominent::after {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 25%;
  top: 50%;
  z-index: -2;
}

.prspan {
  background-color: #fff;
  z-index: 2;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.carousel--item--pro {
  min-height: 450px;
  border-radius: 20px;
}
.main--carousel--prom {
  min-height: 400px;
}
@media (max-width: 870px) {
  .carousel--item--pro {
    min-height: 500px;
    border-radius: 20px;
  }
  .main--carousel--prom {
    min-height: 500px;
  }
}

@media (max-width: "305px") {
  .carousel--content--sub {
    margin-top: 0.2rem;
  }
  .carousel--content {
    margin-top: 0.2rem;
  }
}

.carousel--overlay {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  z-index: 0;
}
.carousel--content {
  position: absolute;
  z-index: 3;
  text-align: center;
  padding: 2.5rem;
  align-items: center;
  margin-top: 2rem;
}
.carousel--content--title {
  font-size: var(--step-1);
  color: #e5cb5e;
}
.carousel--content--sub {
  font-size: var(--step-0);
  color: #fff;
  margin-top: 3.5rem;
}

@media (max-width: 430px) {
  .carousel--content--sub {
    margin-top: 0.5rem;
  }
  .carousel--content {
    margin-top: 0.5rem;
  }
}
