:root {
  --step--2: clamp(0.78rem, 0.65rem + 0.66vw, 1.12rem);
  --step--1: clamp(0.94rem, 0.76rem + 0.9vw, 1.4rem);
  --step-0: clamp(1.13rem, 0.88rem + 1.22vw, 1.75rem);
  --step-1: clamp(1.35rem, 1.02rem + 1.63vw, 2.19rem);
  --step-2: clamp(1.62rem, 1.19rem + 2.17vw, 2.73rem);
  --step-3: clamp(1.94rem, 1.37rem + 2.88vw, 3.42rem);
  --step-4: clamp(2.33rem, 1.58rem + 3.79vw, 4.27rem);
  --step-5: clamp(2.8rem, 1.81rem + 4.96vw, 5.34rem);
}

.main--cont--about {
  background-image: url(../assets/images/ABOUT.jpg);
  min-height: 91vh;
  background-position: center;
  background-size: cover;
  margin-top: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

@font-face {
  font-family: GilroyRegular;
  src: url(../assets/fonts/Gilroy-FREE/Gilroy-Light.otf);
}

.main--title--aboutus {
  font-family: "GilroyRegular", sans-serif;
  font-weight: 600;
  font-size: var(--step-1);
  color: #26873e;
  text-align: center;
}

.main--facility--aboutus {
  font-family: "GilroyRegular", sans-serif;
  font-size: var(--step-2);
  color: #26873e;
  text-align: center;
  font-weight: 600;
}

.main--name--aboutus {
  font-family: "GilroyRegular", sans-serif;
  font-size: var(--step-0);
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.main--occupation--aboutus {
  font-family: "GilroyRegular", sans-serif;
  font-size: var(--step--1);
  color: #fff;
  text-align: center;
}

.main--sub--aboutus {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step-0);
  padding: 0.5rem 0;
  color: #fff;
  text-align: center;
}

.main--para--aboutus {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--2);
  padding: 2rem 2rem;
  text-align: center;
  color: #fff;
}

.cross--line--aboutus {
  position: relative;
}

.cross--line--aboutus::before {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  left: 22%;
  top: 50%;
  z-index: 0;
}

.cross--line--aboutus::after {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background-color: #26873e;
  position: absolute;
  right: 22%;
  top: 50%;
  z-index: 0;
}

@media (max-width: 886px) {
  .cross--line--aboutus::before {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 15%;
    top: 50%;
    z-index: 0;
  }
  .cross--line--aboutus::after {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 15%;
    top: 50%;
    z-index: 0;
  }
}

@media (max-width: 1440px) {
  .cross--line--aboutus::before {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 15%;
    top: 50%;
    z-index: 0;
  }
  .cross--line--aboutus::after {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 15%;
    top: 50%;
    z-index: 0;
  }
}

@media (max-width: 645px) {
  .cross--line--aboutus::before {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    left: 7%;
    top: 50%;
    z-index: 0;
  }
  .cross--line--aboutus::after {
    content: "";
    display: block;
    width: 10%;
    height: 2px;
    background-color: #26873e;
    position: absolute;
    right: 7%;
    top: 50%;
    z-index: 0;
  }
}

@media (max-width: 464px) {
  .cross--line--aboutus::after,
  .cross--line--aboutus::before {
    visibility: hidden;
  }
}

.tspanabu {
  background-color: #ffffff;
  z-index: 2;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.club--best {
  font-size: var(--step-0);
}
.founders {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  font-size: var(--step-0);
  font-weight: 800;
  text-align: center;
}

.about--li {
  padding: 1rem 0;
}

.no-of-members {
  text-align: center;
  margin-top: 35px;
  font-size: var(--step-4);
  font-weight: 700;
  color: #e5cb5e;
  font-family: "GilroyRegular", sans-serif;
  margin-bottom: 8px;
}

.about-follow--main--title {
  font-size: var(--step-0);
  font-family: "GilroyMedium", sans-serif;
  color: #26873e;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 2%;
  margin-bottom: -5px;
}

.about-facility-list-txt {
  font-size: var(--step-0);
  font-family: "GilroyMedium", sans-serif;
  list-style-type: none;
  margin-left: 55px;
}

.about-facility-list-logo {
  background-image: url(../assets/images/list/star_logo.png);
  background-repeat: no-repeat;
  list-style-type: none;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .about-facility-list-logo {
    background-image: url(../assets/images/list/star_logo.png);
    background-repeat: no-repeat;
    list-style-type: none;
    padding-top: 7px;
    padding-bottom: 7px; 
  }
}

@media (max-width: 480px) {
  .about-facility-list-logo {
    background-image: url(../assets/images/list/star_logo.png);
    background-repeat: no-repeat;
    list-style-type: none;
    padding-top: 7px;
    padding-bottom: 7px; 
  }
}

.fac-list-box {
  width: 50%;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 65px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .fac-list-box {
    width: 90%; 
    margin-top: 20px;
    margin-bottom: 40px; 
  }
}

@media (max-width: 480px) {
  .fac-list-box {
    width: 95%;
    margin-top: 15px;
    margin-bottom: 30px; 
  }
}


.about-para-csc {
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--1);
}

.about--main--title {
  font-size: var(--step-3);
  letter-spacing: 0.2em;
  font-family: "GilroyMedium", sans-serif;
  color: #fff;
  font-weight: 800;
  z-index: 10;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@font-face {
  font-family: GilroyMedium;
  src: url(../assets/fonts/Gilroy-FREE/Gilroy-Medium.ttf);
}

.textab {
  position: relative;
}

.textab::before {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 31%;
  top: 50%;
}

.textab::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  right: 31%;
  top: 50%;
}

@media (max-width: 1440px) {
  .textab::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 28%;
    top: 50%;
  }
  .textab::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 28%;
    top: 50%;
  }
}

@media (max-width: 1350px) {
  .textab::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 27%;
    top: 50%;
  }
  .textab::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 27%;
    top: 50%;
  }
}

@media (max-width: 1250px) {
  .textab::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 26%;
    top: 50%;
  }
  .textab::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 26%;
    top: 50%;
  }
}

@media (max-width: 1190px) {
  .textab::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 24%;
    top: 50%;
  }
  .textab::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 24%;
    top: 50%;
  }
}

@media (max-width: 1110px) {
  .textab::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 22%;
    top: 50%;
  }
  .textab::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 22%;
    top: 50%;
  }
}

@media (max-width: 1000px) {
  .textab::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 19%;
    top: 50%;
  }
  .textab::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 19%;
    top: 50%;
  }
}

@media (max-width: 900px) {
  .textab::before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 18%;
    top: 50%;
  }
  .textab::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    right: 18%;
    top: 50%;
  }
}

@media (max-width: 760px) {
  .textab::before {
    visibility: hidden;
  }
  .textab::after {
    visibility: hidden;
  }
}

.about--btn {
  padding: 12px 30px;
  margin-top: 2rem;
  color: #fff;
  background-color: #26873e;
  border: 1px solid #26873e;
  border: none;
  font-family: "GilroyMedium", sans-serif;
  font-size: var(--step--2);
  transition: all 0.3s;
}

.about--btn:hover {
  background-color: #5cb572;
}

.about--btn--container {
  display: flex;
  justify-content: center;
  align-items: center;
}
